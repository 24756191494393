button {
  border:0;
}
.btn {
  background:$green;
  border-radius:4px;
  border:0;
  color:$navy;
  cursor: pointer;
  display:inline-block;
  font-family:$title-font-family;
  font-size: 16px;
  font-style: normal;
  font-weight:700;
  line-height:1.34em;
  padding:8px 15px;
  position:relative;
  text-transform:uppercase;
  letter-spacing: 1px;
  &:active {
    top:1px;
  }
  &:hover {
    text-decoration: none;
    color: #FFFFFF;
  }
}

.back-btn {
  display:inline-block;

}

/* Social Media */
.social-items {
  margin:35px auto;
  a {
    width:50%;
    display:inline-block;
    text-align:center;
    padding:5px;
    color:#fff;
    font-family:$title-font-family;
    font-size: 14px;
    font-weight:900;
    text-transform: uppercase;
    &:hover {  text-decoration:none; }
    i {
      display:inline-block;
      margin:2.5px 17.5px 0 0;
      font-size:20px;
    }
  }
  a[href^="https://www.facebook.com"] {
    background:#425E99;
    &:hover { background:rgba(#425E99, 0.7); }
  }
  a[href^="https://twitter.com"] {
    background:#1C94E0;
    &:hover { background:rgba(#1C94E0, 0.7); }
  }
}


i.more-info-ico {
  display: inline-block;
  margin-left:5px;
  width:20px;
  height:20px;
  border-radius:100%;
  border:1px #fff solid;
  text-align:center;
  line-height: 18px;
  padding-left:1px;
}

.btn-txt {
  font-size: 16px;
  color: #698C9D;
  line-height: 22px;
  font-weight:600;
  display:inline-block;
  margin: 0 20px;
  border-bottom:2px solid #698C9D;
  &:hover {
    text-decoration: none;
  }
}

.btnapp{
  background-color: #000;
  padding: 10px 20px;
  color: #fff;
  margin: 0 10px;
  min-width:200px;
  min-width: 200px !important;
  display: inline-block;
  border-radius: 4px;
  max-height: 60px;
  img{
     height: 100%;
     height: 43px;
  }
}
