
form {
	width:100%;
	.row {
		margin-left:0;
		margin-right:0;
		display: block;
		.col-full {
			display: block;
		}
		.col-half {
			display: inline-block;
			width:calc(50% - 10px);
			&:nth-child(odd) {
				margin-right:10px;
			}
			&:nth-child(even) {
				margin-left:10px;
			}
			@media(max-width:460px) {
				width:100%;
				&:nth-child(odd) { margin-right:0; }
				&:nth-child(even) { margin-left:0; }
			}
		}
	}
}
label {
	font-size: 16px;
	color: #A6295C;
	letter-spacing: 0;
	line-height: 1.4;
	font-style: normal;
	font-weight: 700;
	margin-bottom:3.5px;
	display: block;
	text-align:left;
}
input, textarea {
	font-family:$standard-font-family;
	width:100%;
  border-radius: 3px;
	padding:10px;
	border:0;
	margin-bottom:18px;
	color: #000;
	border: 1px solid #DCDCDC;
}
textarea {
	min-height:170px
}

input[type="text"],
input[type="email"],
input[type="password"],
textarea {
	-webkit-appearance:none;
}

button {
	background:$green;
	font-family:$title-font-family;
	padding:10px 20px;
	font-size: 12px;
	color: #10153D;
	letter-spacing: 1px;
	font-weight:700;
	border-radius:2px;
	text-transform: uppercase;
	&.alt {
		background:#14153E;
		color:#fff;
		display: block;
		width:100%;
	}

}

#validation-msg {
	display: block;
	margin-bottom:50px;
	border-left:3px #AA3464 solid;
	padding:10px 25px;
	background:#F4F2D4;
}

.select_style {
	overflow: hidden;
	display: inline-block;
	position: relative;
	cursor: pointer;
  background: #F4FBFD;
  border-radius: 3px;
  font-family:$standard-font-family;
  font-size: 15px;
  width:100%;
	position:relative;
	border: 1px solid #D0CFCF;
	margin-bottom:9px;
	font-weight:700;
	color:#B5B5B5;
  @media (max-width:900px) { font-size:15px; line-height: 1em; }
  @media (max-width:600px) { font-size:14px; line-height: 1em; }
  @media (max-width:450px) { font-size:12px; line-height: 1em; }
	&:after {
		content: "\f0dd";
		font-family: FontAwesome;
		position: absolute;
		color:#00354F;
		top:8px;
		right:10px;

	}
	select {
	  -moz-appearance: none;
	  -webkit-appearance: none;
		appearance:none;
		background:none;
		background:transparent;
		border:none;
		cursor:pointer;
		outline:none;
		padding:10px;
	  width:100%;
		option { padding-right:45px; }
	}
}

.form-wrap {
	background:url('../imgs/footer_img.jpg') center no-repeat;
	background-size:cover;
	color:#fff;
	padding:60px;
	text-align:center;
	.inner-wrap {
		max-width:932px;
		margin:0 auto;
		width:100%;
		h3 {
			font-size: 26px;
			color: #FFFFFF;
			font-weight:800;
			margin-bottom:35px;
			text-transform: uppercase;
		}
		input {
			width:calc(33.33% - 10px);
			@media(max-width:900px) {
				width:100%;
			}
			&[name="email_txt"] {
				margin:0 15px;
				@media(max-width:900px) {
					margin:0 0 15px;
				}
			}
		}
	}
}

.inline-form-wrap {
	background: #F0F0F0;
	padding:75px 0;
	text-align:center;
	h4 {
		font-size:16px;
		color:#4A4A4A;
		font-weight:600;
		text-transform: uppercase;
		@media(max-width:900px) {
			font-size:15px;
		}
		@media(max-width:750px) {
			font-size:14px;
		}
	}
	h2 {
		font-size:32px;
		line-height: 1.25em;
		font-weight:700;
		text-transform: uppercase;
		@media(max-width:900px) {
			font-size:29px;
		}
		@media(max-width:750px) {
			font-size:27px;
		}
		@media(max-width:600px) {
			font-size:24px;
		}
		@media(max-width:450px) {
			font-size:22px;
		}
	}
	form {
		margin:35px 0 0;
		input[name="name_txt"],
		input[name="email_txt"],
		input[name="telephone_txt"] {
			width:calc(33% - 15px);
			@media(max-width:750px) {
				width:100%;
				margin:0;
			}
		}
		input[name="email_txt"] {
			margin-left:22.5px;
			margin-right:22.5px;
			@media(max-width:750px) {
				margin-left:0;
				margin-right:0;
				margin-top:10px;
				margin-bottom:10px;
			}
		}
		button {
			display: inline-block;
			width:auto;
			font-weight:500;
			text-transform: capitalize;
			font-size:16px;
			margin-top:20px;
		}
	}
}

.white-bg {
	.inline-form-wrap {
		background:#fff;
	}
}

.fancybox-inner {
	h3 {
		padding:25px 25px 0;
		font-size: 23px;
		color: #333333;
		font-weight:700;
		margin-bottom:25px;
	}
	fieldset {
		&:nth-child(1),
		&:nth-child(2) {
			padding:0 25px;
		}
	}
	#loader {
		display: none;
		background-color:#fff;
	}
	.response {
		text-align:center;
		font-size:16px;
		display: block;
		margin:50px 25px;
		font-style: italic;
	}
}
