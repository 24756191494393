.contact-wrap {
  padding:50px 0;
  h1 {
    margin:0;
  }
  .map {
    margin:40px 0;
  }
  .row {
    margin:0;
    display: block;
    padding:40px 0;
    .col {
      display: inline-block;
      vertical-align: top;
      &:nth-child(1) {
        width:calc(50% + 50px);
        padding-right:100px;
        vertical-align: top;
        label {
          display: block;
        }
        h2 {
          font-size: 23px;
          color: #32323A;
          margin-top:0;
        }
        @media(max-width:900px) {
          width:calc(100%);
          padding-right:0;
          margin-bottom:50px;
        }
      }
      &:nth-child(2) {
        width:calc(50% - 50px);
        padding-left:40px;
        border-left:5px #32323A solid;
        vertical-align: top;
        color:#616161;
        font-size:14px;
        @media(max-width:900px) {
          width:100%;
        }
        h3 {
          font-size: 23px;
          color: #32323A;
          margin:0 0 20px;
        }
        h4 {
          font-size: 18px;
          color: #616161;
          letter-spacing: 0;
          line-height: 1.5;
        }
        p {
          &.postcode {
            font-size: 16px;
            color: #616161;
            letter-spacing: 0;
            line-height: 1.1;
            margin:20px 0 30px;
          }
        }
        div {
          display:block;
          font-size:18px;
          font-weight:700;
          margin-bottom:5px;
          i {
            display: inline-block;
            width:16px;
            font-size:100%;
          }
          span {
            padding-left:14px;
            display: inline-block;
          }

        }
        .telephone {
          a {
            color:#32323A;
          }
        }
        .email {
          a {
            color:#AA3464
          }
        }
      }
    }
  }
}
