.about-overview-items {
  .row {
    padding:100px 0;
    margin:0;
    background-color:#DEE0E3;
    @media(max-width:900px) { padding:75px 0; }
    @media(max-width:600px) { padding:62.5px 0; }
    @media(max-width:450px) { padding:50px 0; }
    .inner-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media(max-width:900px) {
        flex-direction: column;
      }
      .col {
        width:calc(50% - 25px);
        @media(max-width:900px) {
          width:100%;
        }
        h2 {
          margin-bottom:15px;
          font-size: 34px;
          color: #0F1744;
          line-height: 1;
          @media(max-width:900px) {
            font-size:30px;
          }
          @media(max-width:600px) {
            font-size:26px;
          }
          @media(max-width:450px) {
            font-size:22px;
          }
        }
        p {
          margin-bottom: 1.5em;
        }
        .img {
          background-size:cover;
          background-position: center;
          background-repeat: no-repeat;
          img { opacity:0; }
        }
        &:nth-child(1) {
          @media(max-width:900px) {
            margin-bottom:25px;
          }

        }
      }
    }
    &:nth-child(even) {
      background-color:#E7E8E8;
      .col:nth-child(1) {
        order:2;
        @media(max-width:900px) {
          order:-1;
        }
      }
    }
    &:nth-child(odd) {
      .col:nth-child(1) {
        @media(max-width:900px) {
          order:-1;
        }
      }
    }
  }
}

.affiliations-items {
  background:#fff;
  text-align:center;
  padding:100px 0;
  h2 {
    margin:0 0 10px;
  }
  p {
    margin:0;
  }
  .col-wrap {
    margin-top:75px;
    display: flex;
    text-align:left;
    justify-content:space-between;
    @media(max-width:900px) {
      display: block;
    }
    @media(max-width:600px) {
      text-align:center;
    }
    .col {
      width:calc(33.33333% - 56px);
      font-size: 14px;
      color: #757575;
      letter-spacing: 0;
      line-height:1.5;
      @media(max-width:900px) {
        display: flex;
        width:100%;
      }
      @media(max-width:600px) {
        display: block;
        width:100%;
      }
      h3 {
        margin:25px 0;
        font-size: 22px;
        color: #0F1744;
        letter-spacing: 0;
        line-height: 1;
      }
      .img-wrap {
        text-align:center;
        height:207px;
        border:1px #E2E2E2 solid;
        display: flex;
        align-items:center;
        justify-content:center;
        padding:25px;
        @media(max-width:900px) {
          width:207px;
          height:207px;
        }

        @media(max-width:600px) {
          margin:0 auto;
        }
      }
      .txt-wrap {
        @media(max-width:900px) {
          width:calc(100% - 207px);
          padding-left:50px;
          h3 { margin:10px 0; }
        }
        @media(max-width:600px) {
          padding-left:0;
          width:100%;
        }
      }
      &:nth-child(2) {
        @media(max-width:900px) {
          margin-top:50px;
          margin-bottom:50px;
        }
      }
    }
  }
}
