.slideshow {
  position: relative;
  z-index: 50;
  .banner-paging-wrap {
    position: absolute;
    bottom:10px;
    left:0;
    width:100%;
    height:40px;
    z-index: 1000;
    .inner-wrap {
      position: relative;
      height:40px;
      .banner-paging {
        position: absolute;
        bottom:0;
        left:0;
      }
    }
  }
  .slide {
    max-height:488px;
    width:100%;
    position: relative;
    background-repeat:no-repeat;
    background-size:cover;
    background-position: center;
    overflow: hidden;
    @media(max-width:1050px) {
      &::after {
        content: " ";
        position: absolute;
        top:0;
        left:0;

        width:100%;

        pointer-events: none;
      }
    }
    .inner-wrap {
      padding-top: 34.55%;
      max-width:1440px;
      margin:0 auto;
      max-height:488px;
      
      @media(max-width:1050px) {

      }

    }

    .txt-wrap {
      padding-left:150px;
      @media(max-width:1050px) {
        padding-left:0;
        position: relative;
        z-index: 500;
      }
      @media(max-width:600px) {
        padding-left:35px;
        padding-right:35px;
      }
      h1 {
        font-size: 64px;
        color:$navy;
        line-height: 0.9;
        display: block;
        max-width:315px;
        font-weight:800;
        margin-top:10px;
        @media(max-width:900px) {
          font-size:54px;
        }
      }
      h2 {
        color:$navy;
        font-size:44px;
        line-height: 1.35em;
        margin:0;
        @media(max-width:900px) {
          font-size:40px;
          line-height: 1.35em;
        }
        @media(max-width:700px) {
          font-size:36px;
          line-height: 1.35em;
        }
        @media(max-width:650px) {
          font-size:32px;
          line-height: 1.35em;
        }
      }
      p {
        margin:10px 0 25px;
        font-weight:600;
        font-size: 16px;
        color: #78787D;
        letter-spacing: 0;
        line-height: 1.222em;
        text-shadow: 0 0 10px rgba(255,255,255,0.79);
        max-width:345px;

      }
      a.btn {
        padding:10px 20px;
        background:$purple;
        color:white;
        font-size:13px;
        &:active {
          color:#fff;
        }
      }
    }
  }
}

.slideshow-btns {
  position: absolute;
  bottom: 20px;
  left:0;
  z-index:500;
  width:100%;
  @media(max-width:800px) {
    display: none;
  }
  span {
    background: #B8D142;
    border-radius: 4px;
    width: 36px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    display: inline-block;
    margin: 0 3.5px;
    cursor: pointer;
    i {
      color:#fff;
    }
  }
}

// sub slideshow
.sub-slideshow {
  margin:0 auto;
  max-width:1400px;
  .slide {
    width:100%;
    background-size:cover;
    background-repeat:no-repeat;
    background-position: center;
    .txt-wrap {
      display: flex;
      height:487px;
      flex-wrap: wrap;
      align-items: center;
      max-width:395px;
      padding-left:50px;
      h1 {
        font-size:64px;
        font-weight: 800;
        line-height: 0.9;
      }
    }
  }
}
