section.ico-wrap {
  background:#DEE0E3;
  padding:90px 0;
  ul {
    display: block;
    max-width:920px;
    margin:0 auto;
    li {
      width:33.333%;
      display: inline-block;
      text-align:center;
      @media(max-width:800px) {
        width:100%;
        display: block;
        &:nth-child(2) {
          margin-top:40px;
          margin-bottom:40px;
        }
      }
      .ico-wrap {
        height:114px;
        display: flex;
        align-items:center;
        text-align:center;
        justify-content:center;
      }
      .txt-wrap {
        h3 {
          font-weight:700;
          font-size:21px;
          color:#3E3D47;
          text-transform: uppercase;
          @media(max-width:900px) { font-size:19px; }
          @media(max-width:600px) { font-size:17px; }
          @media(max-width:450px) { font-size:15px; }
        }
        p {
          color:#616161;
          font-size:20px;
          font-weight:300;
          @media(max-width:900px) { font-size:18px; }
          @media(max-width:600px) { font-size:16px; }
          @media(max-width:450px) { font-size:14px; }
        }
      }
    }
  }
}


section.offers-wrap {
  padding:45px 0;
  text-align:center;
  background:#fff;
  h2 {
    font-size: 34px;
    color: #0F1744;
  }
  p {
    font-size: 16px;
    color: #9a9a9a;
    letter-spacing: 0;
    line-height: 1.4;
    max-width: 82%;
    margin: 0 auto;
    margin-bottom: 18px;
    @media(max-width:660px) {
      max-width:100%;
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    max-width:980px;
    justify-content: space-around;
    margin:50px auto;
    li {
      max-width:calc(33% - 25px);
      margin:0 12.5px;
      //@media(max-width:960px) {
      //  width:50%;
      //  display: inline-block;
      //  max-width: none;
      //  margin-top:50px;
      //  &:nth-child(1),
      //  &:nth-child(2) { margin-top:0; }
     // }
      //@media(max-width:600px) {
      //  display: block;
      //  width:100%;
     //   &:nth-child(2) { margin-top:50px; }
     // }
      @media(max-width:660px) {
        max-width:calc(50% - 25px);
      }
      @media(max-width:460px) {
        max-width:calc(100% - 25px);
      }
      .img-wrap {
        overflow: hidden;
        width:100%;
        display: block;
        margin-bottom:25px;
        //@media(max-width:960px) {
        //  max-width:200px;
        //  margin:0 auto 15px;
        //}
       // @media(max-width:600px) {
       //   height:auto;
       // }
      }
      .txt-wrap {
        font-size: 17px;
        color: #0F1744;
        font-weight:700;
        padding:10px 25px;
      }
      a.btn {
        background: #A6295C;
        border-radius: 2px;
        font-size: 12px;
        color: #FFFFFF;
        letter-spacing: 1px;
        font-weight:700;
      }
    }
    &.overviews-promotions-wrap {
      li {
        max-width:calc(25% - 25px);
        margin:0 12.5px 25px;
        @media(max-width:900px) {
          max-width:calc(33% - 25px);
        }
        @media(max-width:660px) {
          max-width:calc(50% - 25px);
        }
        @media(max-width:460px) {
          max-width:calc(100% - 25px);
        }
        .img-wrap {
          margin-bottom:0;
        }
        .txt-wrap {
          margin-bottom:15px;
        }
      }
    }
  }
}


section.promotions-txt {
  padding:0;
  background: linear-gradient(90deg, #DEE0E3 50%, #E4E4E7 50%);
  @media(max-width:960px) {
    background:none;
  }
  .inner-wrap {
    max-width:1300px;
    width:100%;
  }
  .two-col-wrap {
    display: flex;
    @media(max-width:960px) {
      display: block;
    }
    .col {
      padding-top:80px;
      padding-bottom:80px;
      width:50%;
      @media(max-width:1425px) {
        padding-left:90px;
        padding-right:90px;
      }
      @media(max-width:960px) {
        width:100%;
        padding-left:50px;
        padding-right:50px;
        text-align:left;
      }
      @media(max-width:600px) {
        padding-left:35px;
        padding-right:35px;
      }
      @media(max-width:450px) {
        padding-left:25px;
        padding-right:25px;
      }
      &:nth-child(1) {
        padding-right:90px;
        background:#DEE0E3;
        @media(max-width:960px) { padding-right:50px; }
        @media(max-width:600px) { padding-right:35px; }
        @media(max-width:450px) { padding-right:25px; }
        p {
          margin-top:1.5em;
          font-size:15px;
          line-height: 1.5;
          color: rgba(#4A4A4A, 0.64);
          @media(max-width:900px) { font-size:14px; }
          @media(max-width:600px) { font-size:13px; }
          @media(max-width:450px) { font-size:12px; }
          &:nth-child(1) {
            margin-top:0;
            font-size: 32px;
            color: #0F1744;
            line-height: 1.05;
            font-style: normal;
            font-weight: 700;
            @media(max-width:900px) { font-size:29px; }
            @media(max-width:600px) { font-size:24px; }
            @media(max-width:450px) { font-size:21px; }
          }
          &:nth-child(2) {
            font-size: 17px;
            color: #0F1744;
            line-height: 1.5;
            @media(max-width:900px) { font-size:16px; }
            @media(max-width:600px) { font-size:15px; }
            @media(max-width:450px) { font-size:14px; }
          }
        }
      }
      &:nth-child(2) {
        background:#E4E4E7;
        padding-left:90px;
        @media(max-width:960px) { padding-left:50px; }
        @media(max-width:600px) { padding-left:35px; }
        @media(max-width:450px) { padding-left:25px; }
      }
    }
  }


}
