
h1, h2, h3 {
  font-family:$title-font-family;
  font-weight:700;
  font-style: normal;
}

h1, h2 {
  font-size:34px;
  line-height:1.2em;
  margin:0 0 30px;
  color:$navy;
  @media (max-width:900px) { font-size:30px; line-height: 1.5em; }
  @media (max-width:600px) { font-size:27px; line-height: 1.5em; }
  @media (max-width:450px) { font-size:24px; line-height: 1.5em; }
}
