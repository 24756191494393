
$title-font-family:	"proxima-nova";
$standard-font-family: "proxima-nova";

$container: 1160;

$orange: #F26631;
$navy: #0E112B;
$purple: #A6295C;

$red: #FD0000;
$green: #B8D142;
$grey: rgba(#9B9B9B,0.11);

$blue: #144B6F;
$dark-blue: #124363;
$deep-blue: #103D5A;

$break-small: 400px;
$break-large: 1130px;
$mobile-nav: 995px;
