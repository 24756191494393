footer {
  position: relative;
  z-index: 300;
  color:white;
  font-size:13px;
  .inner-wrap {
    display: flex;
    justify-content:space-between;
    align-content:space-between;
    @media(max-width:900px) {
      display: block;
      text-align:center;
      .col:nth-child(1) {
        margin-bottom:25px;
      }
    }
  }
  .top {
    padding:50px 0;
    background:#fff;
    .col {
      vertical-align: top;
      @media(max-width:900px) {
        text-align:center;
        display: block;
        width:100%;
      }
      img {
        vertical-align: top;
        padding-right:110px;
        display: inline-block;
        @media(max-width:900px) {
          padding-right:0;
          margin-bottom:35px;
          display: block;
          margin-left:auto;
          margin-right:auto;
          padding-top:0;
        }
      }
      ul.sitemap {
        columns:3;
        column-gap:50px;
        display:inline-block;
        font-size:16px;
        font-style: normal;
        font-weight: 400;
        @media(max-width:600px) {
          columns: 2;
        }
        @media(max-width:400px) {
          columns: 1;
        }
        li {
          a { color:#4A4A4A; }
        }
      }
      h3 {
        color:$navy;
        display:inline-block;
        font-size: 14px;
        letter-spacing: 0.88px;
        font-weight:800;
        margin:0 0 10px;
        text-transform: uppercase;
      }
      ul.contact {
        li {
          color:#818283;
          font-weight:400;
          font-style: normal;
          font-size:14px;
          &.social-media {
            margin-top:20px;
          }
        }
      }
      &:nth-child(2) {
        text-align:right;
        @media(max-width:900px) {
          text-align:center;
          margin-top:35px;
        }
      }
    }
    .social-media {
      a {
        display: inline-block;
        text-align:center;
        margin-left:15px;
        position: relative;
        i {
          color:$navy;
          font-size:26px;
        }
      }
    }
  }
  .btm {
    padding:22px 0;
    background:$navy;
    font-size:13px;
    font-style: normal;
    font-weight: 400;
    a {
      color:#fff;
    }
    @media(max-width:600px) {
      span { display:block; }
    }
    .utility {
      a {
        font-size:12px;
        margin-left:15px;
        border-radius:2px;
        border:2px #FFFFFF solid;
        background:none;
        color:#fff;
        &:nth-child(1) {
          &:hover {
            background:none;
          }
        }
        &:nth-child(2) {
          border:2px $purple solid;
          background:$purple;
          color:#fff;
        }
      }
    }
  }
}
