.main-content {
  background:#DEE0E3;
  padding:80px 0;
  &.home-content {
  }
  .inner-wrap {
    max-width:1070px;
    margin:0 auto;
    display: flex;


    .txt-wrap {
      display: inline-block;
      vertical-align: top;
      width:50%;
      padding-right:60px;
      h1 {
        margin:0 0 25px 0;
        color:#0F1744;
      }
      p {
        margin:0 0 1.5em;
      }
      a.btn {
        font-size:12px;
        padding:10px 20px;
      }
      @media(max-width:900px) {
        width:100%;

        padding-right:0;
        h1 {
          margin-top:0;
        }
      }
      &.full-width {
        width:100%;
        padding-right:0;
        a {
          color:#0F1744;
          font-weight:700;
        }
      }
    }
    .img-wrap {
      display: inline-block;
      vertical-align: top;
      width:48%;
      float: right;
      img {
        width:100%;
        border-radius: 4px;
      }
      @media(max-width:900px) {
        display: none;
        img {
          width:auto;
        }
      }
    }
  }
}



.recent-offers-wrap {
  text-align:center;
  padding:65px 0;
  h2 {
    font-size:34px;
    margin:0;
    line-height: 1em;
  }
  p {
    margin:20px 0 40px 0;
    color:#4A4A4A;
    font-size:18px;
  }
  ul {
    list-style: none;
    margin:20px auto 0;
    padding:0;
    max-width:1065px;
    li {
      display: inline-block;
      width:330px;
      margin:0 12.5px;
      a.img-wrap {
        display: block;
      }
      img {

      }

    }
  }

  .controls-wrap {
    text-align:center;
    margin-top:35px;
    .cycle-prev,
    .cycle-next {
      background: #f1f1f1;
      border: 1px solid #c3c3c3;
      border-radius: 4px;
      width:43px;
      height:43px;
      text-align:center;
      line-height: 43px;
      display: inline-block;
      margin:0 3.5px;
      cursor: pointer;
      i {
        color: #3E3E3D;
      }
    }
  }
}

.brands-wrap {
   @extend .recent-offers-wrap;
   background:#fff;
   border-top:1px #E3E3E3 solid;
   .brands-items-wrap {
     position: relative;
     .slick-btn {
       position: absolute;
       top:20px;
       cursor: pointer;
       i {
         font-size:44px;
       }
     }
     .prev-btn { left:20px; }
     .next-btn { right:20px; }
     .slick-list { border-right:1px #fff solid; }
     ul {
        padding: 0 20px;
        @media(max-width:1170px) {
          padding: 0 60px;
        }
        li {
         display: inline-block;
         text-align:center;
         width:150px;
         height:80px;
         margin:0;
         line-height: 80px;
         border-left:1px #E3E3E3 solid;
         @media(max-width:500px) {
           border-left: none;
         }
         &:nth-child(1) {
           border-left:0;
         }
         img {
           max-width:80%;
           max-height:80px;
         }
       }
     }
  }
}

.divisions-wrap {
  @extend .recent-offers-wrap;
  background:#DEE0E3;
  ul {
    li {
      padding:20px;
      background:#fff;
      width:calc(33.33333% - 13.3333333333px);
      line-height: 1em;
      margin:0 0 20px;
      &:nth-child(3n+2) {
        margin-left:20px;
        margin-right:20px;
      }
      @media(max-width:900px) {
        width:calc(50% - 10px);
        &:nth-child(3n+2) {
          margin-left:0;
          margin-right:0;
        }
        &:nth-child(2n+1) {
          margin-right:10px;
        }
        &:nth-child(2n+2) {
          margin-left:10px;
        }
      }
      @media(max-width:500px) {
        width:100%;
        margin-top:10px;
        &:nth-child(1) {
          margin-top:0;
        }
        &:nth-child(2n+1) {
          margin-right:0;
        }
        &:nth-child(2n+2) {
          margin-left:0;
        }
      }
      .img-wrap {
        img {
          max-width:100%;

        }
      }
      .btn {
        display: block;
        width:100%;
        font-size:12px;
        margin-top: 20px;
      }
    }
  }
}
