html {
  box-sizing: border-box;
}

body {
  font:17px/1.5em $standard-font-family;
  position: relative;
  background-color:#F9F9F9;
  @media (max-width:900px) { font-size:15px; line-height: 1.5em; }
  @media (max-width:600px) { font-size:14px; line-height: 1.5em; }
}

*, *:before, *:after {
  box-sizing: inherit;
}

a {
  cursor: pointer;
}

strong {
  font-weight:700;
}


fieldset {
  border:0;
  margin:0;
  padding:0;
}

img {
  max-width:100%;
}

.center { text-align:center; }
.clear { clear:both; }


#page-wrap {
  position: relative;
}

#page-wrap-inner {
  position: relative;
  right: 0;
  z-index: 100;
  width: 100%;
  transition: right 0.3s ease;
  background-color:#F9F9F9;
  min-height: 100vh;
  a {
    position: relative;
    &:active {
      top:1px;
    }
  }
  &.push {
    right: 15.625em;
  }
}

#productFeatureLoader  { display:none; }


#loader {
  display:block;
  margin:35px auto;
}

#zero {
  display:none;
  text-align:center;
  color:#fff;
  padding:35px 0;
}

.default-pointer {
  cursor: default;
}

.hide-from-mobile {
  display: block;
  @media(max-width:900px) {
    display:none;
  }
}

.show-only-on-mobile {
  display: none;
  @media(max-width:900px) {
    clear:both;
    display:inline-block;
  }
}

.loader {
    display: none;
}

.content-wrap {
  p { margin:0 0 1.5em; }
}
