header {
  .top {
    padding:25px 0;
    .inner-wrap {
      display:flex;
      justify-content:space-between;
      align-content:space-between;
      .logo {

      }
      .utility {
        @media(max-width:800px) {
          display: none;
        }
        a {
          font-size:12px;
          margin-left:15px;
          border-radius:2px;
          border:2px #0F1744 solid;
          background:#fff;
          &:nth-child(1) {
            &:hover {
              background:#0F1744;
            }
          }
          &:nth-child(2) {
            border:2px $purple solid;
            background:$purple;
            color:#fff;
          }
        }
      }
    }
  }
  .btm {
    background:$green;
    .inner-wrap {
      ul {
        list-style: none;
        margin:0;
        padding:0;
        border-left:1px #9BAE44 solid;
        li {
          display: inline-block;
          font-size:12px;
          color:$navy;
          letter-spacing: 1px;
          font-weight:700;
          text-transform: uppercase;
          border-right:1px #9BAE44 solid;
          a {
            color:$navy;
            padding:15px 20px;
            display: inline-block;
            text-decoration: none;
            &.highlight {
              background:#DEE0E3;
              color:$navy;
            }
            &:hover {
              text-decoration: underline;
            }
          }
          .parent-sub { // sub menu
            display: none;
            position: absolute;
            width:100%;
            left:0;
            top:160px;
            z-index: 2000;
            background:#DEE0E3;
            padding:50px 0;
            .inner-wrap {
              display:flex;
              justify-content: stretch;
              align-content: stretch;
              max-width:1160px;
            }
            ul.parent-links {
              list-style: none;
              border:0;
              display: inline-block;
              width:140px;
              @media(max-width:980px) {
                width: 20%;
              }
              li {
                display: block;
                border:0;
                font-size:16px;
                margin-top:10px;
                text-transform: capitalize;
                letter-spacing: 0;
                &:nth-child(1) {
                  margin-top:0;
                }
                a {
                  padding:0;
                  text-decoration: none;
                  &:hover {
                    text-decoration: underline;
                  }
                  &.active {
                    color:$purple;
                  }
                }
              }
            }
            .sub-nav-items {
              display: inline-block;
              border-left:1px #979797 solid;
              border-right:1px #979797 solid;
              margin:0 50px;
              padding:0 75px 0 55px;
              width:400px;
              @media(max-width:980px) {
                width: 80%;
                border-right: none;
              }
              ul.third {
                display: none;
                &.show {
                  display: block;
                }
                border:0;
                columns: 2;
                column-gap:55px;
                li {
                  border:0;
                  display:block;
                  font-size:16px;
                  font-style: normal;
                  font-weight: 400;
                  letter-spacing: 0;
                  margin-bottom:10px;
                  text-transform: capitalize;
                  a {
                    border:0;
                    padding:0;
                  }
                }
              }
            }
            .sub-nav-txt {
              display: inline-block;
              width:calc(100% - 640px);
              @media(max-width:980px) {
                display: none;
              }
              h3 {
                font-size: 22px;
                color: #0F1744;
                text-transform: capitalize;
                margin:0 0 20px;
              }
              p {
                color: #4A4A4A;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                letter-spacing: 0;
                line-height: 1.4em;
                text-transform: none;
              }
            }
          }
          &.active {
            background:$navy;
            a {
              color:#fff;
            }
          }
        }
      }
    }
    @media(max-width:800px) {
      display: none;
    }

    .dropdown-menu {
      display: none;
      position: absolute;
      z-index: 1000;
      background: #DEE0E3;
      padding: 0;
      width: 256px;
      ul {
        margin: 0;
        border: 0;
        padding: 50px 40px;
        display: block;
        li {
          margin: 0;
          border: 0;
          padding: 0;
          display: block;
          text-transform: capitalize;
          font-size: 16px;
          color: #A6295C;
          line-height: 33px;
          letter-spacing: 0;
          a {
            padding: 0;
            margin: 0;
            border: 0;
            color: #0F1744;
            &.active {
              color:#A6295C;
            }
          }
        }
      }
    }

  }
}

/* Navigation Button */
#mobile-burger-btn {
  background:$green;
  cursor: pointer;
  display:none;
  height: 40px;
  margin-top:20px;
  text-align:center;
  transition: right 0.5s;
  width: 40px;
  z-index: 100;
  color:$navy;
  i {
    font-size:28px;
    position: relative;
    top:7px;
    &:nth-child(2) {
      display:none;
    }
  }
  @media(max-width:800px) {
    display: inline-block;
    position: fixed;
    right:20px;
    top:10px;
    z-index: 9999;

  }
  &.menu-open {
    right:270px;
    i {
      &:nth-child(1) { display: none; }
      &:nth-child(2) { display: inline-block; }
    }
  }
}


#mobile-nav-inner-items {
  ul
    li {
        border-bottom: 1px solid rgba(255,255,255,0.1);
        line-height: 40px;
      ul {
        li {
          border-bottom: none;
          line-height: 20px;
          a { font-weight: 100; text-transform: capitalize;}
        }
      }
    }
}

/* User Logged in  */
header .top .inner-wrap .utility.loggin-nav ul {
  display:inline-block;
  font-family: "proxima-nova",sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  letter-spacing: 0.92px;
  text-transform: uppercase;
}
header .top .inner-wrap .utility.loggin-nav ul li {
  display:inline-block;
  text-transform: uppercase;
}
header .top .inner-wrap .utility.loggin-nav ul li a {
  border:0;
  color: #A2A2A2;
}
header .top .inner-wrap .utility.loggin-nav ul li a:hover {
  background:none;
  text-decoration: underline;
}
header .top .inner-wrap .utility.loggin-nav a.btn.account-btn {
  border: 2px #0F1744 solid;
  background: #fff;
  color:#0F1744;
}
header .top .inner-wrap .utility.loggin-nav a.btn.logout-btn {
  border: 2px #A6295C solid;
  background: #A6295C;
  color: #fff;
}
