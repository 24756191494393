.inner-wrap {
  max-width:$container + px;
  width:90%;
  margin:auto;
}

.call-to-action-wrap {
  padding:70px 0;
  border-top:1px #E3E3E3 solid;
  border-bottom:1px #E3E3E3 solid;
  text-align:center;
  h3 {
    font-size:34px;
    font-weight:700;
    margin:0 0 15px;
  }
  p {
    max-width:600px;
    margin:0 auto 1.5em;
    color:#4A4A4A;
    font-size:16px;
    line-height: 1.5em;
  }
  .btn-wrap {
    a.btn {
      font-size:12px;
      font-weight:700;
      border-radius:2px;
      margin:0 5px;
      &:nth-child(1) {
        background:$purple;
        color:#fff;
      }
      &:nth-child(2) {

      }
      @media(max-width:600px) {
        display: block;
        width:100%;
        margin:0 auto;
        max-width:230px;
        &:nth-child(1) {
          margin-bottom:5px;
        }
      }
    }
  }
}

.content-wrap{
  display:flex;
  align-items:center;
}
