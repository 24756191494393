body.fancybox-active {
	overflow: hidden;
}

body.fancybox-iosfix {
	position: fixed;
    left: 0;
    right: 0;
}

.fancybox-is-hidden {
	position: absolute;
	top: -9999px;
	left: -9999px;
	visibility: hidden;
}

.fancybox-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 99992;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	backface-visibility: hidden;
	transform: translateZ(0);
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.fancybox-outer {
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}

.fancybox-bg {
	background: rgb(30,30,30);
	opacity: 0;
	transition-duration: inherit;
	transition-property: opacity;
	transition-timing-function: cubic-bezier(.47,0,.74,.71);
}

.fancybox-is-open .fancybox-bg {
	opacity: 0.87;
	transition-timing-function: cubic-bezier(.22,.61,.36,1);
}

.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption-wrap {
	position: absolute;
	direction: ltr;
	z-index: 99997;
	opacity: 0;
	visibility: hidden;
	transition: opacity .25s, visibility 0s linear .25s;
	box-sizing: border-box;
}

.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption-wrap {
	opacity: 1;
	visibility: visible;
	transition: opacity .25s, visibility 0s;
}

.fancybox-infobar {
	top: 0;
	left: 0;
	font-size: 13px;
	padding: 0 10px;
	height: 44px;
	min-width: 44px;
	line-height: 44px;
	color: #ccc;
	text-align: center;
	pointer-events: none;
	user-select: none;
	-webkit-touch-callout: none;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-font-smoothing: subpixel-antialiased;
	mix-blend-mode: exclusion;
}

.fancybox-toolbar {
	top: 0;
	right: 0;
	margin: 0;
	padding: 0;
}

.fancybox-stage {
	overflow: hidden;
	direction: ltr;
	z-index: 99994;
	-webkit-transform: translate3d(0, 0, 0);
}

.fancybox-is-closing .fancybox-stage {
  overflow: visible;
}

.fancybox-slide {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	overflow: auto;
	outline: none;
	white-space: normal;
	box-sizing: border-box;
	text-align: center;
	z-index: 99994;
	-webkit-overflow-scrolling: touch;
	display: none;
	backface-visibility: hidden;
	transition-property: transform, opacity;
}

.fancybox-slide::before {
	content: '';
	display: inline-block;
	vertical-align: middle;
	height: 100%;
	width: 0;
}

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
	display: block;
}

.fancybox-slide--image {
	overflow: visible;
}

.fancybox-slide--image::before {
	display: none;
}

.fancybox-slide--video .fancybox-content,
.fancybox-slide--video iframe {
	background: #000;
}

.fancybox-slide--map .fancybox-content,
.fancybox-slide--map iframe {
	background: #E5E3DF;
}

.fancybox-slide--next {
	z-index: 99995;
}

.fancybox-slide > * {
	display: inline-block;
	position: relative;
	padding: 24px;
	margin: 44px 0 44px;
	border-width: 0;
	vertical-align: middle;
	text-align: left;
	background-color: #fff;
	overflow: auto;
	box-sizing: border-box;
}

.fancybox-slide > title,
.fancybox-slide > style,
.fancybox-slide > meta,
.fancybox-slide > link,
.fancybox-slide > script,
.fancybox-slide > base {
	display: none;
}

.fancybox-slide .fancybox-image-wrap {
	position: absolute;
	top: 0;
	left: 0;
	margin: 0;
	padding: 0;
	border: 0;
	z-index: 99995;
	background: transparent;
	cursor: default;
	overflow: visible;
	transform-origin: top left;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	backface-visibility: hidden;
	user-select: none;
}

.fancybox-can-zoomOut .fancybox-image-wrap {
	cursor: zoom-out;
}

.fancybox-can-zoomIn .fancybox-image-wrap {
	cursor: zoom-in;
}

.fancybox-can-drag .fancybox-image-wrap {
	cursor: grab;
}

.fancybox-is-dragging .fancybox-image-wrap {
	cursor: grabbing;
}

.fancybox-image,
.fancybox-spaceball {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	border: 0;
	max-width: none;
	max-height: none;
	user-select: none;
}

 .fancybox-spaceball {
	z-index: 1;
}

.fancybox-slide--iframe .fancybox-content {
	padding: 0;
	width: 80%;
	height: 80%;
	max-width: calc(100% - 100px);
	max-height: calc(100% - 88px);
	overflow: visible;
	background: #fff;
}

.fancybox-iframe {
	display: block;
	margin: 0;
	padding: 0;
	border: 0;
	width: 100%;
	height: 100%;
	background: #fff;
}

.fancybox-error {
	margin: 0;
	padding: 40px;
	width: 100%;
	max-width: 380px;
	background: #fff;
	cursor: default;
}

.fancybox-error p {
	margin: 0;
	padding: 0;
	color: #444;
	font-size: 16px;
	line-height: 20px;
}


/* Buttons */

.fancybox-button {
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    width: 44px;
    height: 44px;
    margin: 0;
    padding: 10px;
    border: 0;
    border-radius: 0;
    background: rgba(30,30,30,0.6);
    transition: color .3s ease;
    cursor: pointer;
	outline: none;
}

.fancybox-button,
.fancybox-button:visited,
.fancybox-button:link {
	color: #ccc;
}

.fancybox-button:focus,
.fancybox-button:hover {
	color: #fff;
}

.fancybox-button[disabled] {
	color: #ccc;
	cursor: default;
    opacity: 0.6;
}

.fancybox-button svg {
    display: block;
	position: relative;
    overflow: visible;
    shape-rendering: geometricPrecision;
}

.fancybox-button svg path {
    fill: currentColor;
    stroke: currentColor;
    stroke-linejoin: round;
    stroke-width: 3;
}

.fancybox-button--share svg path {
	stroke-width: 1;
}

.fancybox-button--play svg path:nth-child(2) {
    display: none;
}

.fancybox-button--pause svg path:nth-child(1) {
    display: none;
}

.fancybox-button--zoom svg path {
	fill: transparent;
}


/* Navigation arrows */

.fancybox-navigation {
    display: none;
}

.fancybox-show-nav .fancybox-navigation {
    display: block;
}

.fancybox-navigation button {
    position: absolute;
    top: 50%;
    margin: -50px 0 0 0;
    z-index: 99997;
	background: transparent;
	width: 60px;
	height: 100px;
	padding: 17px;
}

.fancybox-navigation button:before {
	content: "";
	position: absolute;
	top: 30px;
	right: 10px;
	width: 40px;
	height: 40px;
	background: rgba(30,30,30,0.6);
}

.fancybox-navigation .fancybox-button--arrow_left {
	left: 0;
}

.fancybox-navigation .fancybox-button--arrow_right {
	right: 0;
}

/* Close button on the top right corner of html content */

.fancybox-close-small {
	position: absolute;
	top: 0;
	right: 0;
	width: 44px;
	height: 44px;
	padding: 0;
	margin: 0;
	border: 0;
	border-radius: 0;
	background: transparent;
	z-index: 10;
	cursor: pointer;
}

.fancybox-close-small:after {
	content: '×';
	position: absolute;
	top : 5px;
	right: 5px;
	width: 30px;
	height: 30px;
	font: 20px/30px Arial,"Helvetica Neue",Helvetica,sans-serif;
	color: #888;
	font-weight: 300;
	text-align: center;
	border-radius: 50%;
	border-width: 0;
	background-color: transparent;
	transition: background-color .25s;
	box-sizing: border-box;
	z-index: 2;
}

.fancybox-close-small:focus {
	outline: none;
}

.fancybox-close-small:focus:after {
	outline: 1px dotted #888;
}

.fancybox-close-small:hover:after {
	color: #555;
	background: #eee;
}

.fancybox-slide--image .fancybox-close-small,
.fancybox-slide--iframe .fancybox-close-small {
	top : 0;
	right : -44px;
}

.fancybox-slide--image .fancybox-close-small:after,
.fancybox-slide--iframe .fancybox-close-small:after {
	font-size: 35px;
	color: #aaa;
}

.fancybox-slide--image .fancybox-close-small:hover:after,
.fancybox-slide--iframe .fancybox-close-small:hover:after {
	color: #fff;
	background: transparent;
}

.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-drag .fancybox-close-small {
	display: none;
}

/* Caption */

.fancybox-caption-wrap {
	bottom: 0;
	left: 0;
	right: 0;
	padding: 60px 2vw 0 2vw;
	background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 20%, rgba(0,0,0,0.2) 40%,rgba(0,0,0,0.6) 80%, rgba(0,0,0,0.8) 100%);
	pointer-events: none;
}

.fancybox-caption {
	padding: 30px 0;
	border-top: 1px solid rgba(255,255,255,0.4);
	font-size: 14px;
	color: #fff;
	line-height: 20px;
	-webkit-text-size-adjust: none;
}

.fancybox-caption a,
.fancybox-caption button,
.fancybox-caption select {
	pointer-events: all;
	position: relative; /* Fix IE11 */
}

.fancybox-caption a {
	color: #fff;
	text-decoration: underline;
}


/* Loading indicator */

.fancybox-slide > .fancybox-loading {
	border: 6px solid rgba(100, 100, 100, .4);
	border-top: 6px solid rgba(255, 255, 255, .6);
	border-radius: 100%;
	height: 50px;
	width: 50px;
	animation: fancybox-rotate .8s infinite linear;
	background: transparent;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -30px;
	margin-left: -30px;
	z-index: 99999;
}

@keyframes fancybox-rotate {
	from { transform: rotate( 0deg ); }
	to   { transform: rotate( 359deg ); }
}


/* Transition effects */

.fancybox-animated {
	transition-timing-function: cubic-bezier(0, 0, 0.25, 1);
}

/* transitionEffect: slide */

.fancybox-fx-slide.fancybox-slide--previous {
	transform: translate3d(-100%, 0, 0);
	opacity: 0;
}

.fancybox-fx-slide.fancybox-slide--next {
	transform: translate3d(100%, 0, 0);
	opacity: 0;
}

.fancybox-fx-slide.fancybox-slide--current {
	transform: translate3d(0, 0, 0);
	opacity: 1;
}

/* transitionEffect: fade */

.fancybox-fx-fade.fancybox-slide--previous,
.fancybox-fx-fade.fancybox-slide--next {
	opacity: 0;
	transition-timing-function: cubic-bezier(.19,1,.22,1);
}

.fancybox-fx-fade.fancybox-slide--current {
	opacity: 1;
}

/* transitionEffect: zoom-in-out */

.fancybox-fx-zoom-in-out.fancybox-slide--previous {
	transform: scale3d(1.5, 1.5, 1.5);
	opacity: 0;
}

.fancybox-fx-zoom-in-out.fancybox-slide--next {
	transform: scale3d(0.5, 0.5, 0.5);
	opacity: 0;
}

.fancybox-fx-zoom-in-out.fancybox-slide--current {
	transform: scale3d(1, 1, 1);
	opacity: 1;
}

/* transitionEffect: rotate */

.fancybox-fx-rotate.fancybox-slide--previous {
	transform: rotate(-360deg);
	opacity: 0;
}

.fancybox-fx-rotate.fancybox-slide--next {
	transform: rotate(360deg);
	opacity: 0;
}

.fancybox-fx-rotate.fancybox-slide--current {
	transform: rotate(0deg);
	opacity: 1;
}

/* transitionEffect: circular */

.fancybox-fx-circular.fancybox-slide--previous {
	transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
	opacity: 0;
}

.fancybox-fx-circular.fancybox-slide--next {
	transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
	opacity: 0;
}

.fancybox-fx-circular.fancybox-slide--current {
	transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
	opacity: 1;
}

/* transitionEffect: tube */

.fancybox-fx-tube.fancybox-slide--previous {
	transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
}

.fancybox-fx-tube.fancybox-slide--next {
	transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
}

.fancybox-fx-tube.fancybox-slide--current {
	transform: translate3d(0, 0, 0) scale(1);
}