.brands {
  padding:115px 0;
  text-align:center;
  background:#fff;
  h2 {
    font-size: 34px;
    color: #0F1744;
    margin:0;
  }
  p {
    font-size: 18px;
    color: #4A4A4A;
    letter-spacing: 0;
    line-height: 30px;
  }

  .tabs-wrapper {
    margin-top:30px;
    display:none;
    align-items:stretch;
    &.desktop-only {
      @media(min-width:960px) {
        display: flex;
      }
    }
    .tabs-wrapper-parent {
      display: inline-block;
      width:354px;
      vertical-align: top;
      position: relative;
      z-index: 200;
      ul {
        list-style: none;
        margin:0;
        padding:0;
        li {
          display:block;
          text-align:center;
          border-left: 1px solid #E3E3E3;
          border-bottom: 1px solid #E3E3E3;
          border-right: 1px solid #E3E3E3;
          border-top:0;
          display: block;
          background:#FBFBFB;
          line-height: 80px;
          height:80px;
          cursor: pointer;
          &:nth-child(1) {
            border-top:1px solid #E3E3E3;
          }
          &.active {
            background:#fff;
            border-right:#fff 1px solid;
          }
          img{
            max-height: 51px;
            max-width: 70%;
          }
        }
      }
    }
    .tabs-wrapper-content {
      width:calc(100% - 354px);
      border: 1px solid #E3E3E3;
      position: relative;
      left:-1px;
      z-index: 100;
      padding-bottom: 20px;
      &.full {
        width:100%;
      }
      .tab-content {
        padding:20px;
        display:none;
        &.active {
          display: block;
        }
        ul {
          display:block;
          li {
            height:197px;
            line-height: 197px;
            display:inline-block;
            width:calc(33.33333% - 20px);
            border:1px solid #E3E3E3;
            margin:10px;
            float: left;
            overflow: hidden;
            @media(max-width:1200px) {
              width:calc(50% - 20px);
            }
            img {
              max-width:85%;
              max-height:85%;
            }
          }
        }
      }
    }
  }

  /// ACCORDIAN BRANDS
  .tabs-mobile-wrapper {
    display: none;
    &.mobile-only {
      @media(max-width:960px) {
        display: block;
      }
    }
    ul {
      list-style: none;
      padding: 0;
      margin:0;
      li {
       margin:10px 0;
       border: 1px solid #E3E3E3;
       a.toggle {
          width: 100%;
          display: block;
          background:#FBFBFB;
          height: 80px;
          line-height: 80px;
          transition: background .3s ease;
          &.active {
            background:#fff;
          }
          img{
            max-height: 51px;
          }
        }
        .inner {
          overflow: hidden;
          display: none;
          padding:10px;
          li {
            display:inline-block;
            height:197px;
            line-height: 197px;
            display:inline-block;
            width:calc(33.33333% - 20px);
            border:1px solid #E3E3E3;
            margin:10px;
            padding:0;
            float: left;
            @media(max-width:700px) {
              width:calc(50% - 20px);
            }
            @media(max-width:500px) {
              width:calc(100% - 20px);
            }
            img {
              max-width:85%;
              max-height:85%;
            }
          }
        }
        &.full {
          a.toggle { display: none;}
          .inner {
            display: block;
          }
        }
      }
    }
  }
}
